<!-- fieldAwesome.vue -->
<template>
	<div>
		<v-swatches swatches="text-advanced" v-model="value"></v-swatches>
		<input type="text" class="form-control" v-model="value">
	</div>
</template>

<script>
import {abstractField} from "vue-form-generator";
import VSwatches from 'vue-swatches'

export default {
	components: {VSwatches},
	mixins: [abstractField]
};
</script>
