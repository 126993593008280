const state = {
	authData: null
};

const getters = {
	authData(state) {
		return state.authData
	},
	isLogged(state) {
		return state.authData !== null
	}
};

const mutations = {
	authData(state, data) {
		state.authData = data
	}
};

const actions = {
	logout({state}) {
		state.authData = null
	}
}

export default {
	name: 'Auth',
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
