import store from '@/store'

export default {
	getPolicy(permission) {
		let permissions = store.getters["User/getUser"].Role.Permissions

		if (permissions[permission] !== undefined) {
			return permissions[permission]
		}

		return 'none'
	},
	canRead(permission) {
		return ['read', 'write'].includes(this.getPolicy(permission))
	},
	canWrite(permission) {
		return ['write'].includes(this.getPolicy(permission))
	}
}
