import doAsync from "../async-util";

export default {
	getRunningJobs() {
		return doAsync({
			method: 'get',
			url: `/jobs/running`
		})
	},
}
