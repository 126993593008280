import Vue from 'vue'
import moment from 'moment'

let getDateString = el => {
	let date = moment(el.dataset._date);
	return date.calendar()
};

let update = (el) => {
	el.innerHTML = getDateString(el)
};

Vue.directive('rel-date', {
	acceptStatement: true,
	bind(el, binding, vnode) {
		el.dataset._date = binding.value
		el.dataset._timeout = setTimeout(() => update(el), 30000)
		update(el)
	},
	update(el) {
		update(el)
	},
	unbind(el) {
		clearInterval(el.dataset._timeout)
	},
});
