import Vue from 'vue'
import permissions from '@/service/permissions'

const mixin = {
	methods: {
		canRead(permission) {
			return permissions.canRead(permission)
		},
		canWrite(permission) {
			return permissions.canWrite(permission)
		}
	}
};

Vue.mixin(mixin)
