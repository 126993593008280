import crud from "./standard-crud";
import doAsync from "../async-util";

const base = '/games'

export default {
	...crud.getStandard(base),
	getGamePaths(){
		return doAsync({
			method: 'get',
			url: `/games/game-paths`
		})
	}
}
