import doAsync from "../async-util";

export default {
	getUser() {
		return doAsync({
			method: 'get',
			url: '/me'
		})
	},
	getTeams() {
		return doAsync({
			method: 'get',
			url: '/me/teams'
		})
	},
	update(model) {
		return doAsync({
			method: 'post',
			url: '/me',
			data: model
		})
	},
	lastLogins() {
		return doAsync({
			method: 'get',
			url: '/me/logs/latest'
		})
	},
}
