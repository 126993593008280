import axios from 'axios'
import store from '@/store/'
import router from '@/router'

/**
 * @returns {string|null}
 */
const auth = () => {

	let authData = store.getters["Auth/authData"];

	//console.log(`authData =>`, authData);

	if (authData !== null) {
		return `Bearer ${authData}`
	}

	return null
};

let client = (options = {}) => {

	let auth_header = auth();

	let custom_headers = {}

	if (auth_header) {
		custom_headers['Authorization'] = auth_header
	}

	if (store.getters['User/currentTeam']) {
		custom_headers['Team'] = store.getters['User/currentTeam'].Id
	}

	return axios.create({
		baseURL: process.env.VUE_APP_API_URL,
		withCredentials: false,
		headers: custom_headers,
		...options
	})
};


export default client
