import client from './client'
import router from '@/router'

const doAsync = ({method, url, data, options = {}}) => {

	if (data && method.toLowerCase() === "get") {
		const esc = encodeURIComponent;
		url = `${url}?` + Object.keys(data)
			.map(k => esc(k) + '=' + esc(data[k]))
			.join('&')
	}

	return new Promise((resolve, reject) => {
		console.log(`Calling ${url} with ${data}`)
		client(options)[method](url, data, options)
			.then(response => {
				console.info(method, url, data, response.data);
				resolve(response.data)
			})
			.catch((error) => {

				if (error.response.status === 403) {
					router.push({name: 'login'})
					return
				}

				console.error(method, url, data, error.response.data);
				if (isNaN(error.response.data.error)) {
					alert(`Error: ${error.response.data.error.message}`);
				} else if (error.response.data.message) {
					alert(`Error: ${error.response.data.message}`);
				}

				reject(error)
			})
	})

};

export default doAsync
