import Vue from 'vue'
import Router from 'vue-router'
import routes from 'vue-auto-routing'
import {createRouterLayout} from 'vue-router-layout'
import store from '@/store'

Vue.use(Router)

const RouterLayout = createRouterLayout(layout => {
	return import('@/layouts/' + layout + '.vue')
})

const shouldLogin = store.getters['Auth/authData'] === null

export default new Router({
	routes: [
		{
			path: '/',
			component: RouterLayout,
			children: routes,
			redirect: shouldLogin ? '/login' : '/dashboard'
		}
	]
})
