import doAsync from "../async-util";

export default {
	getList() {
		return doAsync({
			method: 'get',
			url: '/permissions'
		})
	}
}
