import Vue from 'vue';
import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';

VMdEditor.use(githubTheme);

import enUS from '@kangc/v-md-editor/lib/lang/en-US';
VMdEditor.lang.use('en-US', enUS);

Vue.use(VMdEditor);
