import api from '@/api'

const state = {
	list: []
};

const getters = {
	list(state) {
		return state.list
	}
};

const mutations = {
	setProjects(state, data) {
		state.list = data
	},
	setProject(state, project) {
		let index = state.list.findIndex(p => p.Uuid === project.Uuid)

		let _current = state.list.slice()

		if (index !== -1) {
			let saved_project = state[index]
			Object.assign(_current[index], saved_project, project)
		} else {
			_current.push(project)
		}

		state.list = _current
	}
};

const actions = {
	async load({commit}) {
		let projects = await api.Projects.getProjects()
		commit('setProjects', projects)
	},
	async delete({state}, uuid) {
		let index = state.list.findIndex(p => p.Uuid === uuid)
		state.list.splice(index, 1)
	},
	async update({commit}, project) {
		commit('setProject', project)
	}
};

export default {
	name: 'Projects',
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
