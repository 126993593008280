import doAsync from "../async-util";

export default {
	getList() {
		return doAsync({
			method: 'get',
			url: '/agents'
		})
	},
	get(user_id) {
		return doAsync({
			method: 'get',
			url: `/agents/${user_id}`
		})
	},
	saveNew(model) {
		return doAsync({
			method: 'put',
			url: `/agents`,
			data: model
		})
	},
	delete(user_id) {
		return doAsync({
			method: 'delete',
			url: `/agents/${user_id}`
		})
	},
	update(user_id, model) {
		return doAsync({
			method: 'post',
			url: `/agents/${user_id}`,
			data: model
		})
	}
}
