const state = {
	current: null
};

const getters = {
	/*
	getCurrent(state) {
		return state.current
	}
	 */
};

const mutations = {
	currentSlot(state, data) {
		state.current = data
	}
};

export default {
	name: 'SlotConfigurator',
	namespaced: true,
	state,
	getters,
	mutations
}
