import store from '@/store'
import doAsync from "../async-util";

export default {
	login(loginData) {

		return doAsync({
			method: 'post',
			url: '/auth',
			data: loginData
		}).then(data => {
			console.log('Data received', data)
			store.commit('Auth/authData', data.token)
			store.commit("User/userData", data.agent)
			return data
		})

	},
	logout() {
		return doAsync({
			method: 'post',
			url: '/auth/logout',
		}).then(data => {
			store.commit('Auth/authData', null)
			store.commit("User/userData", null)
			return data
		})
	}
}
