import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
	storage: window.localStorage
})

import Auth from "./Auth";
import User from "./User";
import SlotConfigurator from "./SlotConfigurator";
import Projects from "./Projects";


export default new Vuex.Store({
	modules: {
		Auth,
		User,
		SlotConfigurator,
		Projects
	},
	plugins: [vuexLocal.plugin]
})
