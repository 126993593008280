import doAsync from "../async-util";

export default {
	getList() {
		return doAsync({
			method: 'get',
			url: `/teams`
		})
	},
	save(data) {
		return doAsync({
			method: 'put',
			url: `/teams`,
			data
		})
	},
	load(team_id) {
		return doAsync({
			method: 'get',
			url: `/teams/${team_id}`
		})
	},
	getTeamMembers(team_id) {
		return doAsync({
			method: 'get',
			url: `/teams/${team_id}/members`
		})
	},
	addTeamMembers(team_id, member_id) {
		return doAsync({
			method: 'put',
			url: `/teams/${team_id}/members`,
			data: member_id
		})
	},
	removeTeamMembers(team_id, member_id) {
		return doAsync({
			method: 'delete',
			url: `/teams/${team_id}/members/${member_id}`
		})
	},
	getTeamProjects(team_id) {
		return doAsync({
			method: 'get',
			url: `/teams/${team_id}/projects`
		})
	},
	addTeamProject(team_id, project_id) {
		return doAsync({
			method: 'put',
			url: `/teams/${team_id}/projects`,
			data: project_id
		})
	},
	removeTeamProject(team_id, project_id) {
		return doAsync({
			method: 'delete',
			url: `/teams/${team_id}/projects/${project_id}`
		})
	},
}
