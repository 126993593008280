import Vue from 'vue';
import VueNumberInput from '@chenfengyuan/vue-number-input';

Vue.use(VueNumberInput)

import VueFormGenerator from 'vue-form-generator'
import 'vue-form-generator/dist/vfg.css'

import fieldColor from "../components/ui/fieldColor";
Vue.component("fieldColor", fieldColor);

Vue.use(VueFormGenerator)
