import doAsync from "../async-util";
import store from '@/store'

export default {
	getProjects(all = false) {
		return doAsync({
			method: 'get',
			url: `/projects/`,
			data: {all}
		})
	},
	saveNewProject(project) {
		return doAsync({
			method: 'put',
			url: '/projects/',
			data: project
		}).then(d => {
			store.dispatch('Projects/update', d)
			return d
		})
	},
	updateProject(uuid, project) {
		return doAsync({
			method: 'post',
			url: `/projects/${uuid}`,
			data: project
		}).then(d => {
			store.dispatch('Projects/update', d)
			return d
		})
	},
	deleteProject(uuid) {
		return doAsync({
			method: 'delete',
			url: `/projects/${uuid}`
		}).then(d => {
			store.dispatch('Projects/delete', uuid)
			return d
		})
	},
	getProject(uuid) {
		return doAsync({
			method: 'get',
			url: `/projects/${uuid}`,
		}).then(d => {
			store.dispatch('Projects/update', d)
			return d
		})
	},

	//PROJECT CONFIGURATIONS
	getProjectConfigurations(uuid) {
		return doAsync({
			method: 'get',
			url: `/project-configs/list/${uuid}`,
		})
	},
	saveNewProjectConfiguration(uuid, config) {
		return doAsync({
			method: 'put',
			url: `/project-configs/${uuid}`,
			data: config
		})
	},
	deleteConfiguration(uuid) {
		return doAsync({
			method: 'delete',
			url: `/project-configs/${uuid}`
		})
	},
	getProjectConfig(cid) {
		return doAsync({
			method: 'get',
			url: `/project-configs/${cid}`
		})
	},
	saveProjectConfig(cid, config) {
		return doAsync({
			method: 'post',
			url: `/project-configs/${cid}`,
			data: config
		})
	},
	saveProjectConfigNote(uuid, cid, note) {
		return doAsync({
			method: 'post',
			url: `/project-configs/${uuid}/config/${cid}/note`,
			data: note
		})
	},

	getAllProjectConfig() {
		return doAsync({
			method: 'get',
			url: `/project-configs/list`
		})
	}
}
