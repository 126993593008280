import me from '@/api/modules/me'
import store from '@/store'

const fetchUser = async () => {
	if (store.getters['Auth/authData']) {
		store.dispatch('User/afterLogin')
	}
}

setInterval(fetchUser, 1000 * 60)
fetchUser()
