import router from '@/router'
import store from '@/store'

function checkRoute(route) {

	if (!route.meta.requiresAuth) {
		return true
	}

	return (route.meta.requiresAuth && store.getters['Auth/isLogged'])
}

router.beforeEach((to, from, next) => {
	console.log('newLocation', to.meta);

	if (checkRoute(to)) {
		next()
	} else {
		next({name: 'login'})
	}
})

checkRoute(router.currentRoute)
