import Auth from './modules/auth'
import Me from './modules/me'
import Projects from './modules/projects'
import Simulator from './modules/simulator'
import Spinner from './modules/spinner'
import Teams from './modules/teams'
import Agents from './modules/agents'
import Reports from './modules/reports'
import Roles from './modules/roles'
import Permissions from './modules/permissions'
import GameplayReports from './modules/gameplay'
import Jobs from './modules/jobs'
import Games from './modules/games'
import Localizations from './modules/localizations'
import crud from "./modules/standard-crud";

export default {
	Auth,
	Me,
	Projects,
	Simulator,
	Spinner,
	Reports,
	Teams,
	Agents,
	Roles,
	Permissions,
	Jobs,
	Games,
	Configurations: crud.getStandard('/configurations'),
	CombinationReports: crud.getStandard('/reports/combinations'),
	GameplayReports,
	Localizations
}
