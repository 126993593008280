import doAsync from "../async-util";

export default {
	getStandard(path) {
		return {
			getList(data) {
				return doAsync({
					method: 'get',
					url: `${path}/`,
					data
				})
			},
			get(id) {
				return doAsync({
					method: 'get',
					url: `${path}/${id}`
				})
			},
			saveNew(model) {
				return doAsync({
					method: 'put',
					url: path,
					data: model
				})
			},
			update(id, model) {
				return doAsync({
					method: 'post',
					url: `${path}/${id}`,
					data: model
				})
			},
			delete(id) {
				return doAsync({
					method: 'delete',
					url: `${path}/${id}`
				})
			}
		}
	}
}
