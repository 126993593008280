import crud from "./standard-crud";
import doAsync from "../async-util";

export default {
	Languages: crud.getStandard('/localization/languages'),
	LocalizationProjects: {
		...crud.getStandard('/localization/localization-projects'),
		tokenList(LocalizationProjectfk) {
			return doAsync({
				method: 'get',
				url: '/localization/localization-projects/bulk/',
				data: {
					filters: JSON.stringify(
						{LocalizationProjectfk}
					)
				}
			})
		},
		tokenBulkSave(data) {
			return doAsync({
				method: 'put',
				url: '/localization/localization-projects/bulk',
				data
			})
		},
		generateContentTranslations(data) {
			return doAsync({
				method: 'post',
				url: '/localization/localization-projects/generate-translations/content',
				data
			})
		},
		generateGuideTranslations(data) {
			return doAsync({
				method: 'post',
				url: '/localization/localization-projects/generate-translations/guide',
				data
			})
		},
		generatePaytableTranslations(data) {
			return doAsync({
				method: 'post',
				url: '/localization/localization-projects/generate-translations/paytable',
				data
			})
		},
		downloadPackage(project_id) {
			return doAsync({
				method: 'get',
				url: `/localization/localization-projects/download-package/${project_id}`,
				options: {
					responseType: 'blob',
				}
			})
		}
	},
	LocalizedContents: {
		...crud.getStandard('/localization/localized-contents'),
		getJSON(LocalizationProjectfk) {
			return doAsync({
					method: 'get',
					url: `/localization/localized-contents/json`,
					data: {
						filters: JSON.stringify({
							LocalizationProjectfk
						})
					}
				}
			)
		},
		remove(languageId) {
			return doAsync({
					method: 'delete',
					url: `/localization/localized-contents/remove/${languageId}`,
			});
		}
	},
	LocalizedGuides: {
		...crud.getStandard('/localization/localized-guides'),
		getTranslations(localization_project_fk) {
			return doAsync({
					method: 'get',
					url: `/localization/localized-guides/${localization_project_fk}/translations`,
				}
			)
		},
		uploadImage(file) {
			let formData = new FormData();
			formData.append("file", file, file.name);

			return doAsync({
					method: 'post',
					url: `/localization/localized-guides/upload-image`,
					data: formData
				}
			)
		},
		save({
				 Language,
				 ProjectId,
				 Text
			 }) {
			return doAsync({
					method: 'post',
					url: `/localization/localized-guides/save`,
					data: {
						Language,
						ProjectId,
						Text
					}
				}
			)
		},
		getLocalized(language, project_id) {
			return doAsync({
					method: 'get',
					url: `/localization/localized-guides/${project_id}/${language}`
				}
			)
		}
	},
	LocalizedPaytable: {
		...crud.getStandard('/localization/localized-paytable'),
		getTranslations(localization_project_fk) {
			return doAsync({
					method: 'get',
					url: `/localization/localized-paytable/${localization_project_fk}/translations`,
				}
			)
		},
		uploadImage(file) {
			let formData = new FormData();
			formData.append("file", file, file.name);

			return doAsync({
					method: 'post',
					url: `/localization/localized-paytable/upload-image`,
					data: formData
				}
			)
		},
		save({
				 Language,
				 ProjectId,
				 Text
			 }) {
			return doAsync({
					method: 'post',
					url: `/localization/localized-paytable/save`,
					data: {
						Language,
						ProjectId,
						Text
					}
				}
			)
		},
		getLocalized(language, project_id) {
			return doAsync({
					method: 'get',
					url: `/localization/localized-paytable/${project_id}/${language}`
				}
			)
		}
	}
}
