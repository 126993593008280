import doAsync from "../async-util";

export default {
	getList() {
		return doAsync({
			method: 'get',
			url: '/roles'
		})
	},
	get(role_id) {
		return doAsync({
			method: 'get',
			url: `/roles/${role_id}`
		})
	},
	saveNew(model) {
		return doAsync({
			method: 'put',
			url: `/roles`,
			data: model
		})
	},
	update(role_id, model) {
		return doAsync({
			method: 'post',
			url: `/roles/${role_id}`,
			data: model
		})
	},
	delete(role_id) {
		return doAsync({
			method: 'delete',
			url: `/roles/${role_id}`
		})
	}
}
