import doAsync from "../async-util";
import crud from "./standard-crud";

export default {
    ...crud.getStandard('/spinner-test'),
    getSpinnerTestList(uuid) {
        return doAsync({
            method: 'get',
            url: `/spinner-test/${uuid}/list`
        })
    },
    getSpinnerResult(spinner_id) {
        return doAsync({
            method: 'get',
            url: `/spinner-test/${spinner_id}/result`
        })
    },
    createSpinnerTest(uuid, model) {
        return doAsync({
            method: 'put',
            url: `/spinner-test/${uuid}`,
            data: model
        })
    }
}
