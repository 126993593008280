import doAsync from "../async-util";

const path = '/reports'

export default {
	getList(project_config_uuid) {
		return doAsync({
			method: 'get',
			url: `${path}/${project_config_uuid}`
		})
	},
	create(report) {
		return doAsync({
			method: 'put',
			url: path,
			data: report
		})
	},
	delete(report_id) {
		if (report_id !== undefined) {
			return doAsync({
				method: 'delete',
				url: `${path}/report/${report_id}`
			})
		}
	},
	downloadExcel(report_id) {
		if (report_id !== undefined) {
			return doAsync({
				method: 'get',
				url: `${path}/report/${report_id}/download`
			})
		}
	},
	getOne(report_id) {
		if (report_id !== undefined) {
			return doAsync({
				method: 'get',
				url: `${path}/report/${report_id}`
			})
		}
	}
}
