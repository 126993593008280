import crud from "./standard-crud";
import doAsync from "../async-util";

const base = '/reports/gameplay'

export default {
	...crud.getStandard(base),
	getPlayersTimeOnDevice(gameplay_reportFK) {
		return doAsync({
			method: 'get',
			url: `${base}/${gameplay_reportFK}/players-time-on-device`
		})
	},
	getPlayersTopAwardHit(gameplay_reportFK) {
		return doAsync({
			method: 'get',
			url: `${base}/${gameplay_reportFK}/players-top-award-hit`
		})
	},
	getPlayersRtpDistribution(gameplay_reportFK) {
		return doAsync({
			method: 'get',
			url: `${base}/${gameplay_reportFK}/players-rtp-distribution`
		})
	},
	getPlayersWinnersRtpDistribution(gameplay_reportFK) {
		return doAsync({
			method: 'get',
			url: `${base}/${gameplay_reportFK}/players-winners-rtp-distribution`
		})
	},
	getTopPlayers(gameplay_reportFK) {
		return doAsync({
			method: 'get',
			url: `${base}/${gameplay_reportFK}/top-players`
		})
	},
}
