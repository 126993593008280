import doAsync from "../async-util";

export default {
	doSpin(uuid, {lines, bet, action, autoplay, extra}) {
		return doAsync({
			method: 'post',
			url: `/server/${uuid}/spin`,
			data: {
				lines,
				bet,
				action,
				autoplay,
				extra
			}
		})
	},
	doGamble(uuid, data) {
		return doAsync({
			method: 'post',
			url: `/server/${uuid}/gamble`,
			data
		})
	},
	checkCombination(uuid, use_reel_stops, params) {
		return doAsync({
			method: 'post',
			url: `/server/${uuid}/check/?stops=${use_reel_stops}`,
			data: params
		})
	},
	doBonus(uuid, bonus, rng = null) {

		let params = {
			bonus,
			data: {
				pick: Math.round(Math.random() * 15)
			}
		}

		if (rng !== null) {
			params.rng = rng
		}

		return doAsync({
			method: 'post',
			url: `/server/${uuid}/bonus`,
			data: params
		})
	},
	initGame(uuid) {
		return doAsync({
			method: 'post',
			url: `/server/${uuid}/init`
		})
	},
	resetTestPersistence(uuid) {
		return doAsync({
			method: 'post',
			url: `/server/${uuid}/reset`
		})
	},
	setExtraValue(uuid, extra_key, extra_value) {
		return doAsync({
			method: 'post',
			url: `/server/${uuid}/state/${extra_key}`,
			data: extra_value
		})
	}
}
